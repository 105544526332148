import TopLayout from "@/Components/templates/TopLayout";

export default function Top() {
  return (
    <TopLayout pageTitle="サイトトップ" isBackImage>
      <section>
        <div className="container mx-auto flex px-5 md:py-4 md:flex-row flex-col items-center justify-center min-h-screen pt-[30%] sm:pt-[0%]">
          <div className="flex flex-col items-center text-center">
            <h1 className="title-font sm:text-5xl text-2xl mb-4 font-medium text-white">
              国政の、今を知る。
            </h1>
            <div className="mb-4 flex items-center text-gray-300 sm:text-lg text-sm">
              <div className="mr-1">～</div>
              <h2 className="leading-relaxed">
                国会議員・国政政党 データ計算可視化システム
              </h2>
              <div className="ml-1">～</div>
            </div>
            <div>
              <div className="text-center pb-4">
                <button
                  onClick={() => (location.href = route("overall_overview"))}
                  className="font-bold w-full h-14 text-white text-sm bg-gradient-to-l from-cyan-500 to-blue-500 rounded py-2 px-4 hover:text-blue-900 hover:shadow-sm hover:shadow-gray-300"
                >
                  全体統計データを表示
                </button>
              </div>

              <div className="flex justify-center mb-4 text-gray-900 font-bold">
                <button
                  onClick={() => (location.href = route("human_list"))}
                  className="h-12 text-sm mr-4 inline-flex items-center bg-gray-100 border-0 py-2 px-5 focus:outline-none hover:bg-gray-200 rounded text-base mt-4 md:mt-0"
                >
                  国会議員検索
                </button>

                <button
                  onClick={() =>
                    (location.href = route("political_party_list"))
                  }
                  className="h-12 text-sm inline-flex items-center bg-gray-100 border-0 py-2 px-5 focus:outline-none hover:bg-gray-200 rounded text-base mt-4 md:mt-0"
                >
                  国政政党一覧
                </button>
              </div>
            </div>
            <h1 className="title-font text-xl mb-4 font-medium text-red-900"></h1>
          </div>
        </div>
      </section>
    </TopLayout>
  );
}
